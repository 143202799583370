import * as React from 'react';
import styled from 'styled-components';
import { CloseIcon } from './IconNew';

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	overflow-y: hidden;
	background-color: rgba(0, 0, 0, 0.5);
`;

const Dialog = styled.div`
	background: #fff;
	border-radius: 5px;
	max-width: 720px;
	max-height: 80vh;
	overflow: auto;

	@media (max-width: 640px) {
		width: 100% !important;
		height: 100%;
		max-height: unset;
	}
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	padding: 16px;
	padding-bottom: 0;
`;

const CloseButton = styled.button`
	border: none;
	outline: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: ${({ theme }) => theme.primaryDark};
	background-color: transparent;
	padding: 4px;
`;

const Content = styled.div`
	padding: 16px;
	padding-top: 0;
`;

type Props = React.PropsWithChildren<{
	onClose: () => void;
	fullWidth?: boolean;
	actions?: React.ReactNode[] | React.ReactNode;
}>;

const Modal = ({ onClose, fullWidth, children }: Props) => {
	const onClick = (event: React.MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		onClose();
	};

	const onClickPrevent = (event: React.MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<Overlay role="overlay" onClick={onClick}>
			<Dialog
				role="dialog"
				className="dialog"
				onClick={onClickPrevent}
				style={{
					width: fullWidth ? 'calc(100% - 64px)' : 'auto',
				}}
			>
				<Header>
					<CloseButton onClick={onClose}>
						<CloseIcon size="medium" />
					</CloseButton>
				</Header>
				<Content>{children}</Content>
			</Dialog>
		</Overlay>
	);
};

export default Modal;
