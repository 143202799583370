import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useApp } from '@contexts/app';
import POPA from '@api/popa';
import CEE from '@api/cee';
import { getUrlParams } from '@utils/http';
import { decode } from '@utils/base32';
import LoadingCard from '@components/LoadingCard';
import ErrorPage from './ErrorPage';

const Container = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface LoginPageProps {
	location: Location;
	register?: boolean;
}

function LoginPage({ location, register = false }: LoginPageProps): JSX.Element {
	const { redirectOnSuccess } = useParams<{ redirectOnSuccess?: string }>();
	const { code } = getUrlParams(location.href);
	const { tokens, setTokens, firebaseAuth } = useApp();
	const [readyToRedirect, setReadyToRedirect] = useState(false);
	const fetchingTokens = useRef(false);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		async function exchangeAccessKeyForTokens() {
			if (code && !tokens && !fetchingTokens.current) {
				try {
					fetchingTokens.current = true;
					const tokenData = await POPA.getTokensFromTempToken(code);
					const user = await CEE.getUser(tokenData.accessToken);
					setTokens({ ...tokenData, ...user });
					setReadyToRedirect(true);
				} catch (e) {
					setError(true);
				}
			}
		}
		exchangeAccessKeyForTokens();
	}, [code, setTokens, tokens]);

	useEffect(() => {
		if (!code && !tokens && !register) {
			window.location.href = POPA.getRedirectToLoginUrl(location.origin + location.pathname);
		}
	}, [code, location.origin, location.pathname, register, tokens]);

	if (readyToRedirect && redirectOnSuccess && firebaseAuth?.currentUser) {
		const navigateTo = decode(redirectOnSuccess);
		return <Navigate to={navigateTo} replace />;
	}

	if (readyToRedirect && !redirectOnSuccess) {
		console.error('LoginPage - redirectOnSuccess is missing!');
	}

	if (error) {
		return <ErrorPage />;
	}

	return (
		<Container role="document">
			<LoadingCard text="Du loggas in" />
		</Container>
	);
}

export default LoginPage;
