import React from 'react';
import Container from '@components/Container';
import { Accordion } from '../Accordion';
import { useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { POPATypes } from '@api/popa/types';
import {
	collection,
	CollectionReference,
	doc,
	getFirestore,
	orderBy,
	query,
	where,
} from 'firebase/firestore';
import LoadingCard from '@components/LoadingCard';

function replaceVariables(html: string, variables: Record<string, string> | undefined): string {
	if (!variables || !html) return html;
	return Object.entries(variables).reduce(
		(acc, [key, value]) => acc.replace(new RegExp(`{{${key}}}`, 'g'), value),
		html
	);
}

function FaqArticle({
	expanded,
	article,
	variables,
	handleChange,
}: {
	expanded: boolean;
	article: POPATypes.FaqArticle;
	variables: Record<string, string> | undefined;
	handleChange: () => void;
}): JSX.Element {
	return (
		<Accordion expanded={expanded} onChange={handleChange} title={article.title}>
			{/* eslint-disable-next-line react/no-danger */}
			<div dangerouslySetInnerHTML={{ __html: replaceVariables(article.html, variables) }} />
		</Accordion>
	);
}

export default function FAQ(): JSX.Element {
	const [expandedId, setExpandedId] = React.useState<string>();
	const variablesDoc = doc(getFirestore(), 'faq/sv');
	const articlesCollection = collection(
		getFirestore(),
		variablesDoc.path,
		'articles'
	) as CollectionReference<POPATypes.FaqArticle>;

	const [variables, variablesLoading] = useDocumentDataOnce<Record<string, string>>(variablesDoc);
	const [articles, articlesLoading] = useCollectionDataOnce(
		query<POPATypes.FaqArticle>(
			articlesCollection,
			where('sortIndex', '>', 0),
			orderBy('sortIndex')
		)
	);

	if (variablesLoading || articlesLoading) {
		return <LoadingCard text="Hämtar FAQ" />;
	}

	return (
		<Container role="complementary" className="FAQ">
			{articles?.map((article) => (
				<FaqArticle
					expanded={expandedId === article.slug}
					key={article.slug}
					article={article}
					variables={variables}
					handleChange={() =>
						expandedId === article.slug
							? setExpandedId('')
							: setExpandedId(article.slug)
					}
				/>
			))}
		</Container>
	);
}
