import styled from 'styled-components';
import FAQ from '@components/FAQ';
import Container from '@components/Container';
import Card from '@components/Card';
import Footer from '@components/Footer';
import { H2 } from '@components/Parts';

const StyledH2 = styled(H2)`
	margin: 20px 36px;
	@media (max-width: 600px) {
		margin-bottom: 16px;
	}
`;

/**
 * FAQ page component.
 * TODO: Describe component.
 */
function FAQPage(): JSX.Element {
	return (
		<>
			<Container role="document" className="StartPage">
				<Card contentMaxWidth={720} leftAligned paddingTop={50}>
					<StyledH2>FAQ</StyledH2>
					<FAQ />
				</Card>
			</Container>
			<Footer />
		</>
	);
}

export default FAQPage;
